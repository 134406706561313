import React from "react"
import Layout from "../components/common/Layout"
import CanvasBar from "../components/common/CanvasBar"
import { useStaticQuery, graphql } from "gatsby"
import Slide from "react-reveal/Slide"

const TheArt = () => {
    const data = useImages()
    return (
        <Layout>
            <div className="flex-center">
                <div className="text-center pt-5 pb-5 font-weight-bold">
                    <h3 className="textslider business-text">
                        <span>Solving</span>
                        <br />
                        Business Problems
                    </h3>
                </div>
            </div>
            <div className="pt-md-5">
                <div className="container clearfix">
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="possibletitle">We make it possible</p>
                            <p className="possilepara">in 4 different steps</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="heading-block possiblecontent fancy-title nobottomborder">
                                <h4>
                                    <span>01.</span>STRATEGY
                                </h4>
                            </div>
                            <p>
                                Brainstorming at the grass root level to get a
                                convincing base communication and marketing
                                strategy.
                            </p>
                        </div>
                        <div className="col-lg-3">
                            <div className="heading-block possiblecontent fancy-title nobottomborder">
                                <h4>
                                    <span>02.</span>CREATE
                                </h4>
                            </div>
                            <p>
                                Develop and optimize compelling, intriguing and
                                personalized digital customer experiences on
                                websites and native apps.{" "}
                            </p>
                        </div>
                        <div className="col-lg-3">
                            <div className="heading-block possiblecontent fancy-title nobottomborder">
                                <h4>
                                    <span>03.</span>PERFORM
                                </h4>
                            </div>
                            <p>
                                Target to ensure the campaign is in right place
                                at the right time by across all screens to
                                increase conversion and deliver results.
                            </p>
                        </div>
                        <div className="col-lg-3 col_last">
                            <div className="heading-block possiblecontent fancy-title nobottomborder">
                                <h4>
                                    <span>04.</span>MEASURE
                                </h4>
                            </div>
                            <p>
                                Our measurement solutions will provide support
                                throughout the entire process of validation of
                                campaign success beyond standard performance
                                metrics.
                            </p>
                        </div>
                    </div>
                    <div className="d-none d-lg-flex justify-content-center align-items-end">
                        <Slide up duration={1000}>
                            <img
                                className="zindex--1"
                                src={data.image1.childImageSharp.fluid.src}
                                alt=""
                            />
                        </Slide>
                        <Slide up duration={1000} delay={500}>
                            <img
                                className="zindex--1"
                                src={data.image2.childImageSharp.fluid.src}
                                alt=""
                            />
                        </Slide>
                        <Slide up duration={1000} delay={1000}>
                            <img
                                className="zindex--1"
                                src={data.image3.childImageSharp.fluid.src}
                                alt=""
                            />
                        </Slide>
                        <Slide up duration={1000} delay={1500}>
                            <img
                                className="zindex--1"
                                src={data.image4.childImageSharp.fluid.src}
                                alt=""
                            />
                        </Slide>
                        <Slide up duration={1000} delay={2000}>
                            <img
                                className="zindex--1"
                                src={data.image5.childImageSharp.fluid.src}
                                alt=""
                            />
                        </Slide>
                    </div>
                </div>
            </div>
            <section className="section-pad bg-light">
                <div className="text-center pb-4">
                    <h2 className="promise-heading">Our Promise?</h2>
                </div>
                <p className="promise-txt">
                    <strong className="font-weight-bold">Decrease</strong> spend
                    by <span className="font-weight-bold">10-40%</span> in a
                    year
                </p>
                <p className="promise-txt">
                    <strong className="font-weight-bold">Increase</strong> sales
                    by <span className="font-weight-bold">10-100%</span> in a
                    year
                </p>
            </section>
            <section
                className="bg-grad d-flex justify-content-center align-items-center text-center flex-column media-kit-height"
                data-scroll-section
            >
                <h1 className="m-0 font-weight-normal">
                    Leveraging Strategy To Solve Buisness Problems
                </h1>
            </section>
            <section className="section-pad">
                <div className="container">
                    <div className="d-flex pb-4">
                        <div className="mr-4">
                            <CanvasBar height={5} width={70} />
                        </div>
                        <div className="flex-grow-1 mt-1">
                            <small className="font-weight-bold">
                                WHAT WE BELIEVE
                            </small>
                            <h1 className="mt-3 font-weight-bold">
                                Our Strategy
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <h2>
                                Understanding your
                                <br /> limitations
                            </h2>
                        </div>
                        <div className="col-md-4">
                            <h2>
                                Learning about
                                <br /> your brand
                            </h2>
                        </div>
                        <div className="col-md-4">
                            <h2>
                                Playing your
                                <br /> strengths
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export const faceImg = graphql`
    fragment faceImg on File {
        childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid
            }
        }
    }
`
const useImages = () =>
    useStaticQuery(graphql`
        {
            image1: file(relativePath: { eq: "faces/face-1.png" }) {
                ...faceImg
            }
            image2: file(relativePath: { eq: "faces/face-2.png" }) {
                ...faceImg
            }
            image3: file(relativePath: { eq: "faces/face-3.png" }) {
                ...faceImg
            }
            image4: file(relativePath: { eq: "faces/face-4.png" }) {
                ...faceImg
            }
            image5: file(relativePath: { eq: "faces/face-5.png" }) {
                ...faceImg
            }
        }
    `)

export default TheArt
