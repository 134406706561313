import React, { useRef, useEffect } from "react"
import drawOnCanvas from "../utils/drawOnCanvas"

const CanvasBar = props => {
    const canvas = useRef(null)

    useEffect(() => {
        if (canvas.current) {
            drawOnCanvas(canvas.current)
        }
    }, [])

    return (
        <canvas
            height={25}
            width={150}
            className="bg-canvas"
            {...props}
            ref={canvas}
        />
    )
}

export default CanvasBar
